import { useLayoutEffect, useMemo, useState } from "react";
import { useAfricanYouth } from "context/africanYouth";
import { t } from "i18next";
import { countries, languages, years, statusList } from "utils/lists";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { Country } from "types/country.types";

const MobileModalDropdown = () => {
  const {
    mobileModalDropdown,
    setActiveLanguage,
    setCountryFilters,
    setFilters,
    setMobileModalDropdown,
    status,
  } = useAfricanYouth();
  const [inputSearch, setInputSearch] = useState<string | null>(null);

  useLayoutEffect(() => {
    document.body.style.overflow = mobileModalDropdown.isMobileModalDropdownOpen
      ? "hidden"
      : "unset";

    if (mobileModalDropdown.section?.includes("countries")) {
      (
        document.querySelector("#inputCountrySearchMobile") as HTMLInputElement
      )?.focus();
    }
  }, [
    mobileModalDropdown.isMobileModalDropdownOpen,
    mobileModalDropdown.section,
  ]);

  const countryEducationList: Country[] = useMemo(() => {
    return [
      ...countries.filter(
        ({ name }) =>
          name === "Kenya" ||
          name === "Rwanda" ||
          name === "Ghana" ||
          name === "Ethiopia" ||
          name === "Nigeria" ||
          name === "Uganda"
      ),
      {
        iso3c: "",
        name: "More coming...",
        license: "Public domain",
        file_url: "",
        url: "",
        disabled: true,
      },
    ];
  }, []);

  const handleItems = (items: any, activeSection: string) =>
    items?.map((item: any, i: number) => (
      <li id={item.name} key={i}>
        <button
          disabled={item.disabled}
          onClick={() => {
            setMobileModalDropdown({
              value: false,
              name: "mobileModalDropdown",
              section: mobileModalDropdown.section,
            });
            setInputSearch("");
            if (mobileModalDropdown.section?.split("/")[0] === "countries") {
              setFilters({
                name: "regionMapSection",
                value: null,
              });
              setFilters({
                name: "regionGendersSection",
                value: null,
              });
              setFilters({
                name: "regionTrendsSection",
                value: null,
              });
              setCountryFilters({
                value: t(`${item.name}`),
                name: mobileModalDropdown.section?.split("/")[1],
              });
            } else if (mobileModalDropdown.section === "languages")
              return setActiveLanguage({
                name: "language",
                value: item.id || item.name,
              });
            else
              return setFilters({
                value:
                  activeSection === "year"
                    ? item.label
                    : item.name || item.id || item.label,
                name: activeSection,
              });
          }}
          css={[
            tw`flex gap-[1rem] items-center w-full`,
            item.disabled && tw`pointer-events-none`,
          ]}>
          {item.file_url && (
            <img width={25} src={item.file_url} alt={item.name} />
          )}
          {mobileModalDropdown.section?.includes("Status") ||
          mobileModalDropdown.section === "status" ||
          mobileModalDropdown.section === "trendsEmployedSector"
            ? t(`${item.name}.title`)
            : t(item.name)}
        </button>
      </li>
    ));

  const handleTemplate = (section: string) => {
    switch (
      section && section.includes("/") ? section.split("/")[0] : section
    ) {
      case "countries":
        return handleItems(
          section.split("/")[1] === "education_section"
            ? countryEducationList
            : countries,
          "countries"
        );
      case "disaggregated":
        return handleItems(
          statusList.find(({ name }) => name === status)?.trends!,
          "disaggregated"
        );
      case "trendsEmployedSector":
        return handleItems(
          statusList
            .find(({ name }) => name === status)
            ?.trends?.find(({ name }) => name === "sectors")?.list!,
          "trendsEmployedSector"
        );
      case "regionGendersSection":
        return handleItems(mobileModalDropdown.data, "regionGendersSection");
      case "regionMapSection":
        return handleItems(mobileModalDropdown.data, "regionMapSection");
      case "years":
        return handleItems(years, "year");
      case "languages":
        return handleItems(languages, "language");
      case "status":
        return handleItems(statusList, "status");
      case "genderStatus":
        return handleItems(statusList, "genderStatus");
      default:
        return <p>{t(`${section}.text`)}</p>;
    }
  };

  return (
    <aside
      css={[
        tw`rounded-[0.25rem 0.25rem 0 0] p-[1rem] fixed transition duration-300 left-0 bg-white shadow w-full opacity-0 bottom-0 translate-y-full z-[1003] flex flex-col gap-[1rem]`,
        mobileModalDropdown.isMobileModalDropdownOpen &&
          tw`opacity-100 translate-y-0`,
      ]}>
      <h3 tw="text-sm text-orange-700">
        {t(
          `${
            mobileModalDropdown.section?.split("/")[0] === "countries"
              ? mobileModalDropdown.section?.split("/")[0]
              : mobileModalDropdown.section
          }.title`
        )}
      </h3>
      {(mobileModalDropdown.section?.includes("region") ||
        mobileModalDropdown.section?.split("/")[0] === "countries") && (
        <input
          tw="w-full pb-[0.5rem] border-b border-b-yellow-600 text-grey-700"
          type="text"
          value={inputSearch!}
          onChange={(e) => setInputSearch(e.target.value)}
          placeholder={
            `${t("type_or_select_a_region_placeholder_mobile")}${t(
              mobileModalDropdown.section?.split("/")[0] === "countries"
                ? "countries.title"
                : "subregions.title"
            ).toLowerCase()}` as string
          }
          id="inputCountrySearchMobile"
          autoComplete="new-password"
        />
      )}
      {mobileModalDropdown.section?.split("/")[0] === "countries" && (
        <h4 tw="text-grey-700">
          {t(`${mobileModalDropdown.section?.split("/")[0]}.text`)}
        </h4>
      )}
      <ul
        className="scrollable"
        tw="flex flex-col gap-[1rem] max-h-[10rem] h-full overflow-y-auto">
        {(mobileModalDropdown.section?.split("/")[0] === "countries" ||
          mobileModalDropdown.section?.includes("region")) &&
        inputSearch
          ? handleTemplate(mobileModalDropdown.section!).filter((item: any) =>
              item.props.id.toLowerCase().includes(inputSearch.toLowerCase())
            )
          : handleTemplate(mobileModalDropdown.section!)}
      </ul>
    </aside>
  );
};

export default MobileModalDropdown;
